<template>
  <div class="flex flex-col w-4/5 max-w-[1200px] mx-auto mt-[80px] mb-[50px]">
    <h1>Polityka Prywatności</h1>
    <p>Ostatnia aktualizacja: 01.10.2023</p>
    <p>
      Twoja prywatność jest dla nas ważna. W tej Polityce Prywatności opisujemy
      rodzaje danych osobowych, które zbieramy oraz w jaki sposób je
      wykorzystujemy, gdy odwiedzasz naszą stronę internetową mpages.pl (zwane
      dalej "Strona"). Prosimy o dokładne zapoznanie się z Polityką Prywatności,
      aby zrozumieć, jakie informacje zbieramy i jak je chronimy.
    </p>
    <h1>1. Jakie Dane Zbieramy</h1>
    <p>
      Korzystając z naszej Strony, możemy zbierać różne rodzaje danych, w tym:
    </p>
    <p>
      Dane osobowe, takie jak imię, adres e-mail oraz numer telefonu, które
      dobrowolnie podajesz, korzystając z formularzy kontaktowych lub
      rejestracyjnych na naszej Stronie.
    </p>
    <p>
      Dane techniczne, takie jak adres IP, typ przeglądarki, system operacyjny i
      informacje o urządzeniu, zbierane automatycznie za pomocą plików cookie i
      innych technologii śledzących.
    </p>
    <p>
      Informacje o Twojej aktywności na Stronie, takie jak odwiedzone strony,
      godziny i daty wizyt oraz kliknięcia w linki.
    </p>
    <h1>2. Cel i Podstawa Prawna Przetwarzania Danych</h1>
    <p>Twoje dane osobowe są przetwarzane w następujących celach:</p>
    <p>Odpowiadanie na Twoje zapytania i komunikowanie się z Tobą.</p>
    <p>
      Dostarczanie Ci informacji, produktów lub usług, które mogą Cię
      zainteresować.
    </p>
    <p>Ulepszanie naszej Strony i dostosowywanie jej do Twoich preferencji.</p>
    <p>
      Monitorowanie ruchu na Stronie oraz analizowanie zachowań użytkowników w
      celach statystycznych i marketingowych.
    </p>
    <p>
      Podstawy prawne przetwarzania danych obejmują Twoją zgodę, wykonanie umowy
      z Tobą oraz nasze prawnie uzasadnione interesy.
    </p>
    <h1>3. Udostępnianie Danych Stronom Trzecim</h1>
    <p>
      Możemy udostępniać Twoje dane osobowe stronom trzecim, takim jak dostawcy
      usług technicznych, marketingowych i analitycznych, aby umożliwić im
      świadczenie usług na naszą rzecz. Dbamy o to, aby strony trzecie spełniały
      odpowiednie standardy ochrony danych.
    </p>
    <h1>4. Bezpieczeństwo Danych</h1>
    <p>
      Wdrożyliśmy odpowiednie środki techniczne i organizacyjne w celu ochrony
      Twoich danych osobowych przed nieautoryzowanym dostępem, utratą lub
      ujawnieniem.
    </p>
    <h1>5. Twoje Prawa</h1>
    <p>
      Masz prawo do dostępu do swoich danych osobowych, ich poprawiania,
      usunięcia, ograniczenia przetwarzania oraz przenoszenia danych. Możesz
      również w dowolnym momencie wycofać swoją zgodę. Aby skorzystać z tych
      praw, skontaktuj się z nami pod adresem m.gondorek.kontakt@gmail.com
    </p>
    <h1>6. Pliki Cookie</h1>
    <p>
      Nasza Strona używa plików cookie. Możesz zarządzać ustawieniami plików
      cookie w swojej przeglądarce.
    </p>
    <h1>7. Zmiany w Polityce Prywatności</h1>
    <p>
      Polityka Prywatności może ulec zmianie w przyszłości. Wszelkie
      aktualizacje będą zamieszczane na tej Stronie.
    </p>
    <p>
      W celu uzyskania dodatkowych informacji lub pytań dotyczących naszej
      Polityki Prywatności, prosimy o kontakt pod adresem
      m.gondorek.kontakt@gmail.com
    </p>
  </div>
</template>

<style scoped>
h1 {
  color: #359e65;
  border-bottom: 1px solid #359e65;
  font-size: 1.8rem;
  margin-top: 20px;
}
p {
  margin-top: 10px;
  text-align: justify;
}
</style>
