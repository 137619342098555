import { createRouter, createWebHistory } from "vue-router";
import MainPage from "@/components/mainPage/MainPage.vue";
import policy from "@/components/policy/Policy.vue";

const routes = [
  {
    path: "/",
    component: MainPage,
    meta: {
      title: "Mpages.pl",
    },
  },
  {
    path: "/policy",
    component: policy,
    meta: {
      title: "Polityka prywatności",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {
  document.title = to.meta.title || "Mpages.pl";
});

export default router;
