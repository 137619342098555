<template>
  <footer class="w-full bg-[#151515] h-[60px] flex">
    <div class="w-4/5 limit m-auto flex justify-between">
      <p class="my-auto">mpages.pl 2024</p>
      <div class="flex gap-[20px]">
        <a href="https://github.com/MiloszGondorek" target="_blank">
          <img src="@/assets/github.png" alt="" />
        </a>
        <a
          href="https://www.linkedin.com/in/milosz-gondorek-428031223/"
          target="_blank"
        >
          <img src="@/assets/linkedin.png" alt="" />
        </a>
        <a href="mailto:m.gondorek.kontakt@gmail.com">
          <img src="@/assets/mail.png" alt="" />
        </a>
      </div>
    </div>
  </footer>
</template>

<style scoped>
img {
  height: 25px;
  width: 25px;
  object-fit: scale-down;
  margin: auto 0;
}
</style>
